






























































































import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiAsterisk, mdiCalendar, mdiClock, mdiClose} from '@mdi/js';
import {Prop} from 'vue-property-decorator';
import {parseISO} from 'date-fns';
import {format} from 'date-fns-tz';
import {InputValidationRules} from 'vuetify';
import {ruleRequired} from '@/utility';
import {FieldValidation} from '@/interfaces';
import linkifyStr from 'linkify-string';

@Component
export default class DateTimePicker extends Vue {
  @Prop({default: null}) value!: Date;
  @Prop({default: {}}) validation!: FieldValidation;
  @Prop({default: ''}) label!: string;
  @Prop({default: null}) input!: (event: Date) => {} | null;
  @Prop({default: ''}) hint!: string;
  private date: string | null = null;
  private time: string | null = null;

  private iconTime = mdiClock;
  private iconCalendar = mdiCalendar;
  private iconClear = mdiClose;
  private iconRequired = mdiAsterisk;
  private menuDate = false;
  private menuTime = false;
  private required = false;
  private rulesDate: InputValidationRules = [];
  private rulesTime: InputValidationRules = [];
  private linkifyStr = linkifyStr;
  created() {
    if (this.value) {
      this.date = format(this.value, 'yyyy-MM-dd', {
        timeZone: 'America/Toronto',
      });
      this.time = format(this.value, 'HH:mm:ss', {timeZone: 'America/Toronto'});
    }
    if (this.validation.required) {
      this.required = true;
      this.rulesDate.push(ruleRequired);
    }

    if (this.validation.dateRangeStart) {
      this.rulesDate.push(
        v =>
          (this.validation.dateRangeStart &&
            v >= this.validation.dateRangeStart) ||
          `Date must be on or after ${this.validation.dateRangeStart}`
      );
    }
    if (this.validation.dateRangeEnd) {
      this.rulesDate.push(
        v =>
          (this.validation.dateRangeEnd && v <= this.validation.dateRangeEnd) ||
          `Date must be on or before ${this.validation.dateRangeEnd}`
      );
    }
    if (this.validation.timeRangeStart) {
      this.rulesDate.push(
        v =>
          (this.validation.timeRangeStart &&
            v >= this.validation.timeRangeStart) ||
          `Time must be on or after ${this.validation.timeRangeStart}`
      );
    }
    if (this.validation.timeRangeEnd) {
      this.rulesDate.push(
        v =>
          (this.validation.timeRangeEnd && v <= this.validation.timeRangeEnd) ||
          `Time must be on or before ${this.validation.timeRangeEnd}`
      );
    }
  }

  clearDate() {
    this.date = '';
    this.$emit('input', '');
  }

  clearTime() {
    this.time = '';
    this.$emit('input', '');
  }

  updateDate(value: string) {
    this.date = value;
    this.update();
  }

  updateTime(value: string) {
    this.time = value;
    this.update();
  }

  update() {
    const date = parseISO(
      String(`${this.date ? this.date : ''} ${this.time ? this.time : ''}`)
    );
    this.$emit('input', date);
  }
}
