import {Module} from 'vuex-class-modules';
import store from '@/store';
import {surveyFieldApi as api} from '@/api';
import {
  SurveyFieldAttributes as Attributes,
  SurveyFieldCreate as Create,
  SurveyFieldResource as Resource,
} from '@/interfaces';
import {QuestionType, ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {isString} from 'lodash';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.SurveyField,
    attributes: {
      id: 0,
      dataType: '',
      displayControlData: [],
      displayControl: [],
      displayControlId: '',
      dropdownChainId: '',
      helpText: '',
      items: [],
      label: '',
      name: '',
      options: {},
      order: 0,
      pageId: 0,
      placeholder: '',
      preQuestionLabel: '',
      preQuestionHelpText: '',
      question: null,
      questionCollectionId: 0,
      questionId: 0,
      readOnly: false,
      repeatable: false,
      storageType: '',
      surveyId: 0,
      type: QuestionType.Short,
      validation: {},
      value: '',
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SurveyFieldModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {
  protected cast(item: Resource): Resource {
    if (Array.isArray(item.attributes.validation)) {
      item.attributes.validation = {};
    }
    if (
      item.attributes.type === QuestionType.Checkbox &&
      item.attributes.value === '1'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      item.attributes.value = true;
    }
    if (
      item.attributes.value &&
      item.attributes.type === QuestionType.CheckboxGroup &&
      isString(item.attributes.value)
    ) {
      item.attributes.value = JSON.parse(item.attributes.value);
    }
    return item;
  }
}

export const surveyFieldModule = new SurveyFieldModule(
  {
    store,
    name: ResourceType.SurveyField,
  },
  () => api,
  defaultItem
);
