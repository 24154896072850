var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-6",attrs:{"outlined":"","elevation":"2"}},[(_vm.listing)?_c('v-skeleton-loader',{attrs:{"type":"table-row@5"}}):_vm._e(),(_vm.complete === false && !_vm.listing)?_c('div',[(_vm.activePageId && _vm.activePage.attributes.label !== 'New Page')?_c('h2',[_vm._v(" "+_vm._s(_vm.activePage.attributes.label)+" ")]):_vm._e(),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{style:({display: 'none'})},[_vm._v(" Pages: "+_vm._s(_vm.surveyPageIds)),_c('br'),_vm._v(" Active Page ID: "+_vm._s(_vm.activePageId)),_c('br'),_vm._v(" Active Page Index: "+_vm._s(_vm.activePageIndex)+" ")]),_c('SurveyFields',{attrs:{"pageId":_vm.activePageId,"surveyId":_vm.surveyId}}),_c('v-row',{staticClass:"justify-end mt-10"},[_c('v-btn',{attrs:{"disabled":_vm.activePageIndex === 0 || _vm.surveyFieldBusy || _vm.submitting,"color":"secondary"},on:{"click":_vm.prevPage}},[_vm._v("Previous")]),_c('v-spacer'),(
            _vm.activePageIndex === _vm.surveyPages.length - 1 ||
              !_vm.activePage.attributes.continue
          )?_c('v-btn',{attrs:{"disabled":!_vm.valid ||
              _vm.surveyFieldBusy ||
              _vm.submitting ||
              _vm.$route.name === 'survey-preview',"loading":_vm.submitting,"color":"primary"},on:{"click":_vm.submit}},[_vm._v("Finish")]):_vm._e(),(
            _vm.activePageIndex !== _vm.surveyPages.length - 1 &&
              _vm.activePage.attributes.continue
          )?_c('v-btn',{attrs:{"disabled":!_vm.valid || _vm.surveyFieldBusy,"color":"primary"},on:{"click":_vm.nextPage}},[_vm._v("Next")]):_vm._e()],1),_c('v-row',{staticClass:"justify-center mt-10"},[(_vm.error)?_c('v-alert',{attrs:{"type":"error","transition":"scale-transition"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)],1)],1):_vm._e(),(_vm.complete)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.survey.attributes.thankYouMessage)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }