




















































import Vue from 'vue';
import Component from 'vue-class-component';
import Page from '@/components/ui/Page.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import Header from '@/components/ui/Header.vue';
import {AlertMessage, SurveyResource} from '@/interfaces';
import {surveyModule} from '@/store/modules';
import Spinner from '@/components/ui/Spinner.vue';
import SurveyPages from '@/components/survey/SurveyPages.vue';
import {surveyApi} from '@/api';
import {Route} from '@/enums';
import SurveyResultsPreview from '@/components/survey/SurveyResultsPreview.vue';
import {mdiDatabaseSearch} from '@mdi/js';

@Component({
  components: {
    SurveyResultsPreview,
    Spinner,
    Header,
    PageTitle,
    Page,
    SurveyPages,
  },
})
export default class Survey extends Vue {
  private alert: AlertMessage = {type: '', message: ''};
  private iconPreview = mdiDatabaseSearch;

  get gettingSurvey(): boolean {
    return surveyModule.getting;
  }

  get survey(): SurveyResource {
    return surveyModule.find('1');
  }

  get preview(): boolean {
    return this.$route.name === Route.SurveyPreview;
  }

  async created(): Promise<void> {
    surveyApi.setSurveyHash(this.$route.params.id);
    surveyApi.setPreviewMode(this.$route.name === Route.SurveyPreview);
    if (this.$route.params.key !== undefined) {
      surveyApi.setSurveyKey(this.$route.params.key);
    }
    try {
      await surveyModule.loadById(`1`);
    } catch (err) {
      console.log('caught');
      this.alert = {type: 'error', message: err.message};
    }
  }
}
