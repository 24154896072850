































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {FieldValidation, SurveyFieldItemResourcePartial} from '@/interfaces';
import {mdiAsterisk} from '@mdi/js';
import {InputValidationRules} from 'vuetify';
import linkifyStr from 'linkify-string';

@Component
export default class CheckboxGroup extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: []}) private items!: SurveyFieldItemResourcePartial[];
  @Prop() private label!: string;
  @Prop({default: false}) private readonly!: boolean;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop() private hint!: string;
  @Prop({default: false}) private required!: boolean;
  @Prop() private value!: string[];
  @Prop({default: false}) private persistentHint!: boolean;
  @Prop({default: {}}) validation!: FieldValidation;
  private iconRequired = mdiAsterisk;
  private rules: InputValidationRules = [];
  private selected: string[] = [];
  private linkifyStr = linkifyStr;

  get values() {
    return this.items.map((item: SurveyFieldItemResourcePartial) => item.value);
  }

  created() {
    // console.log('created', this.name, this.value);
    // console.log('typeof', typeof this.value);
    if (this.value && typeof this.value === 'object') {
      this.selected = this.value;
    } else if (this.value) {
      this.selected = [this.value];
    } else {
      this.selected = [];
    }

    if (this.validation.required) {
      //this.rules.push(ruleRequired);
      this.rules.push(
        () => this.selected.length > 0 || 'This field is required'
      );
    }
  }

  update() {
    // console.log('select', this.selected);
    this.$emit('input', this.selected);
  }
}
