











import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {Snackbar} from '@/interfaces';
import {mdiClose} from '@mdi/js';

@Component
export default class NotificationBar extends Vue {
  @Prop() private snackbar!: Snackbar;
  private iconClose = mdiClose;
}
