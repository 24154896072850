









































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  Snackbar,
  SurveyFieldAttributes,
  SurveyFieldResource,
} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {surveyFieldModule} from '@/store/modules/surveyFieldModule';
import SurveyField from '@/components/survey/SurveyField.vue';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import {QuestionType, ResourceType} from '@/enums';
import {cloneDeep, orderBy} from 'lodash';

@Component({
  components: {
    SurveyField,
    NotificationBar,
  },
})
export default class SurveyFields extends Vue {
  @Prop() private pageId!: string;
  @Prop() private surveyId!: string;
  private snackbar: Snackbar = {visible: false, message: ''};
  private questionType = QuestionType;

  get busy(): boolean {
    return surveyFieldModule.busy;
  }

  get getting(): boolean {
    return surveyFieldModule.getting;
  }

  get listing(): boolean {
    return surveyFieldModule.listing;
  }

  get surveyFieldsAll(): SurveyFieldAttributes[] {
    return orderBy(surveyFieldModule.list, ['attributes.order'])
      .filter(field => field.id?.substr(0, 3) !== 'new')
      .filter(field => String(field.attributes.pageId) === this.pageId)
      .map(surveyField => surveyField.attributes);
  }

  get surveyFields(): SurveyFieldAttributes[] {
    return orderBy(surveyFieldModule.list, ['attributes.order'])
      .filter(surveyField => surveyField.id?.substr(0, 3) !== 'new')
      .map(surveyField => surveyField.attributes)
      .filter(
        surveyFieldAttribute =>
          String(surveyFieldAttribute.pageId) === this.pageId
      )
      .filter(
        field => field.type !== QuestionType.Hidden //&&
        //field.type !== QuestionType.QuestionCollectionPlaceholder
      )
      .filter(field => !field.questionId);
  }

  /**
   * Get all the survey fields in the specified question collection.
   * Placeholders should be excluded
   * @param questionCollectionId
   */
  getSurveyFieldsInQuestionCollection(questionCollectionId: string) {
    return this.surveyFieldsAll.filter(
      field =>
        String(field.questionCollectionId) === String(questionCollectionId) &&
        field.questionId
    );
  }

  getDataSetKeysInQuestionCollection(questionCollectionId: string): number[] {
    return this.getSurveyFieldsInQuestionCollection(questionCollectionId)
      .map(field => field.name)
      .map(name => {
        const match = name.match(/^(.*)\.r([0-9]+)\.f([0-9]+)$/);
        return match ? Number(match[2]) : 0;
      })
      .filter((value, index, array) => array.indexOf(value) === index);
  }

  getSurveyFieldsInQuestionCollectionR(
    questionCollectionId: string,
    repeat: number
  ): SurveyFieldAttributes[] {
    const reFirst = new RegExp(`^(.*)\\.r([0-9]+)\\.f([0-9]+)$`, 'g');

    if (repeat === 0) {
      return this.surveyFieldsAll.filter(
        field =>
          String(field.questionCollectionId) === String(questionCollectionId) &&
          field.questionId &&
          !field.name.match(reFirst)
      );
    }

    // does the field name contain a r index?
    const re = new RegExp(`^(.*)\\.r(${repeat})\\.f([0-9]+)$`, 'g');
    return this.surveyFieldsAll.filter(
      field =>
        String(field.questionCollectionId) === String(questionCollectionId) &&
        field.questionId &&
        field.name.match(re)
    );
  }

  repeat(field: SurveyFieldAttributes) {
    // create a copy of the field we want to repeat
    const newAttrs = cloneDeep(field);

    // duplicate help text will be annoying so we hide it
    field.helpText = '';

    // we set repeatable to false so the button doesn't show (the repeated element can still be repeated)
    field.repeatable = false;

    // build a unique and predictable name attribute
    const found = newAttrs.name.match(/^(.*)\.r([0-9]+)\.f([0-9]+)$/);
    newAttrs.name = found
      ? `${found[1]}.r${parseInt(found[2]) + 1}.f${found[3]}`
      : (newAttrs.name = `${newAttrs.name}.r1.f${field.id}`);

    // use a negative id as this is a fake id
    newAttrs.id = Math.abs(newAttrs.id) * -1 - 1;

    // reset the value
    if (!field.readOnly) {
      newAttrs.value = null;
    }

    // create the new survey field resource
    const newField = {
      attributes: newAttrs,
      id: String(newAttrs.id),
      type: ResourceType.SurveyField,
    } as SurveyFieldResource;

    surveyFieldModule.localAdd(newField);
  }

  async repeatQc(field: SurveyFieldAttributes) {
    const qcId = field.questionCollectionId;

    // get all the fields in this collection on this page
    const allQcFields = this.surveyFieldsAll.filter(
      field =>
        field.questionCollectionId === qcId &&
        field.type !== QuestionType.QuestionCollectionPlaceholder
    );
    const lastField = allQcFields[allQcFields.length - 1];
    let order = lastField.order;

    // parse the last field
    const found = lastField.name.match(/^.*\.r([0-9]+)\.f[0-9]+$/);
    const nextR = found ? parseInt(found[1]) + 1 : 1;

    const re = new RegExp(`^.*\\.r${nextR - 1}\\.f[0-9]+$`);

    // grab latest set of fields
    const qcFields =
      nextR > 1
        ? allQcFields.filter(field => field.name.match(re))
        : allQcFields;

    qcFields.forEach(field => {
      // create a copy of the field we want to repeat
      const newAttrs = cloneDeep(field);

      // duplicate help text will be annoying so we hide it
      field.helpText = '';

      // we set repeatable to false so the button doesn't show (the repeated element can still be repeated)
      field.repeatable = false;

      // build a unique and predictable name attribute
      const found = newAttrs.name.match(/^(.*)\.r([0-9]+)\.f([0-9]+)$/);
      newAttrs.name = found
        ? `${found[1]}.r${parseInt(found[2]) + 1}.f${found[3]}`
        : (newAttrs.name = `${newAttrs.name}.r1.f${field.id}`);

      // use a negative id as this is a fake id
      newAttrs.id = Math.abs(newAttrs.id) * -1 - qcFields.length;

      // reset the value
      if (!field.readOnly) {
        newAttrs.value = null;
      }
      newAttrs.order = order;

      //console.log('order', newAttrs.name, newAttrs.id, order);
      order++;

      // create the new survey field resource
      const newField = {
        attributes: newAttrs,
        id: String(newAttrs.id),
        type: ResourceType.SurveyField,
      } as SurveyFieldResource;

      surveyFieldModule.localAdd(newField);
    });
  }

  /**
   * Removes all survey fields in a question collection data set locally (does not persist)
   * @param qcId Question Collection ID
   * @param repeat Repeat level for determining data set
   */
  async repeatQcRemove(qcId: string, repeat: number) {
    // console.log('remove', repeat);
    // console.log(this.getSurveyFieldsInQuestionCollectionR(qcId, repeat));
    this.getSurveyFieldsInQuestionCollectionR(qcId, repeat).forEach(field =>
      surveyFieldModule.localDelete(String(field.id))
    );
  }
}
