














































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {DisplayControlDataPartial} from '@/interfaces';
import {DisplayControlAttributes} from '@/interfaces/resources/DisplayControlResource';

@Component
export default class DisplayControl extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: []}) private data!: DisplayControlDataPartial;
  @Prop({default: null})
  private displayControl!: null | DisplayControlAttributes;
  @Prop() private label!: string;
  @Prop({default: false}) private readOnly!: boolean;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop() private helpText!: string;
  @Prop({default: false}) private required!: boolean;
  @Prop({default: () => []}) private rules!: boolean;
  @Prop() private value!: string;
  @Prop({default: false}) private persistentHint!: boolean;

  @Prop() id!: string;
  @Prop({default: null}) input!: () => {} | null;
  private headers: Record<string, string>[] = [];
  private items: Record<string, string>[] = [];

  // get itemsPrimary(): SelectItem[] {
  //   return dropdownChainModule.list
  //     .filter(item => String(item.attributes.parentId) === String(this.id))
  //     .map(item => ({
  //       text: item.attributes.label,
  //       value: item.id,
  //     }));
  // }

  async created(): Promise<void> {
    if (!this.data || !this.data.tableContents) {
      return;
    }

    const data = this.data.tableContents;
    if (data.length > 0) {
      const headerRow = Object.keys(data[0]);

      this.headers = headerRow.map(col => ({
        text: col,
        value: col,
      }));
      this.items = data;
    }
  }
  save() {
    this.$emit('input', this.items);
  }
  addRow(): void {
    if (!this.data || !this.data.tableContents) {
      return;
    }
    const data = this.data.tableContents;
    if (data.length > 0) {
      const keys = Object.keys(data[0]);
      const newRow: Record<string, string> = {};
      keys.forEach(key => (newRow[key] = ''));
      this.data.tableContents.push(newRow);
      //console.log(this.data.tableContents);
    }
  }
}
