import {Module} from 'vuex-class-modules';
import store from '@/store';
import {placeAutocompleteApi as api} from '@/api';
import {
  PlaceAutocompleteAttributes as Attributes,
  PlaceAutocompleteCreate as Create,
  PlaceAutocompleteResource as Resource,
} from '@/interfaces';
import {QuestionType, ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.PlaceAutocomplete,
    attributes: {
      id: '',
      placeId: '',
      description: '',
      mainText: '',
      secondaryText: '',
    },
  };
};

@Module
class PlaceAutocompleteModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {
  protected cast(item: Resource): Resource {
    return item;
  }
}

export const placeAutocompleteModule = new PlaceAutocompleteModule(
  {
    store,
    name: ResourceType.PlaceAutocomplete,
  },
  () => api,
  defaultItem
);
