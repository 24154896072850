














































import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiAsterisk, mdiCalendar, mdiClose} from '@mdi/js';
import {Prop} from 'vue-property-decorator';
import {FieldValidation} from '@/interfaces';
import {InputValidationRules} from 'vuetify';
import {format} from 'date-fns-tz';
import {ruleRequired} from '@/utility';
import {parseISO} from 'date-fns';
import linkifyStr from 'linkify-string';

@Component
export default class DatePicker extends Vue {
  @Prop({default: null}) value!: Date;
  @Prop({default: {}}) validation!: FieldValidation;
  @Prop({default: ''}) label!: string;
  @Prop({default: ''}) hint!: string;
  @Prop({default: false}) private readOnly!: boolean;

  private date: string | null = null;
  private iconCalendar = mdiCalendar;
  private iconClear = mdiClose;
  private iconRequired = mdiAsterisk;
  private menuDate = false;
  private required = false;
  private rules: InputValidationRules = [];
  private linkifyStr = linkifyStr;

  created() {
    if (this.value) {
      this.date = format(this.value, 'yyyy-MM-dd', {
        timeZone: 'America/Toronto',
      });
    }
    if (this.validation.required) {
      this.required = true;
      this.rules.push(ruleRequired);
    }

    if (this.validation.dateRangeStart) {
      this.rules.push(
        v =>
          (this.validation.dateRangeStart &&
            v >= this.validation.dateRangeStart) ||
          `Date must be on or after ${this.validation.dateRangeStart}`
      );
    }
    if (this.validation.dateRangeEnd) {
      this.rules.push(
        v =>
          (this.validation.dateRangeEnd && v <= this.validation.dateRangeEnd) ||
          `Date must be on or before ${this.validation.dateRangeEnd}`
      );
    }
  }

  clear() {
    this.date = '';
    this.$emit('input', '');
  }

  update(value: string) {
    this.date = value;
    if (!value) {
      this.$emit('input', '');
    }
    const date = format(parseISO(this.date), 'yyyy-MM-dd', {
      timeZone: 'UTC',
    });

    this.$emit('input', date);
  }
}
