import {Module} from 'vuex-class-modules';
import store from '@/store';
import {placeApi as api} from '@/api';
import {
  PlaceAttributes as Attributes,
  PlaceCreate as Create,
  PlaceResource as Resource,
} from '@/interfaces';
import {QuestionType, ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Place,
    attributes: {
      id: 0,
      street: '',
      street2: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
    },
  };
};

@Module
class PlaceModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  protected cast(item: Resource): Resource {
    return item;
  }
}

export const placeModule = new PlaceModule(
  {
    store,
    name: ResourceType.Place,
  },
  () => api,
  defaultItem
);
