


































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import {placeAutocompleteModule} from '@/store/modules/placeAutocompleteModule';
import {
  PlaceAttributes,
  PlaceAutocompleteResource,
  SelectItem,
} from '@/interfaces';
import {placeModule} from '@/store/modules/placeModule';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import linkifyStr from 'linkify-string';
import {InputValidationRules} from 'vuetify';
import {ruleRequired} from '@/utility';

@Component({
  components: {RequiredLabel},
})
export default class Address extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop() private label!: string;
  @Prop({default: false}) private readOnly!: boolean;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop() private hint!: string;
  @Prop({default: false}) private required!: boolean;
  @Prop() private value!: PlaceAttributes | string;
  @Prop({default: false}) private persistentHint!: boolean;
  private rules: InputValidationRules = [];
  private search = null;
  private timer: null | number = null;
  private address: PlaceAttributes = {
    id: 0,
    street: '',
    street2: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
  };
  private showManualAddress = false;
  private linkifyStr = linkifyStr;

  get loading() {
    return placeAutocompleteModule.loading;
  }

  get items() {
    return placeAutocompleteModule.list.map(
      (item: PlaceAutocompleteResource) => ({
        text: item.attributes.description,
        value: item.attributes.placeId,
      })
    );
  }

  created() {
    if (this.value && typeof this.value !== 'string') {
      this.address = {...this.value};
      this.showManualAddress = true;
    }
    if (this.required) {
      this.rules.push(ruleRequired);
    }
  }

  async selectPlace(item: SelectItem) {
    const pr = await placeModule.loadById(String(item.value));
    this.address.street = pr.attributes.street;
    this.address.street2 = pr.attributes.street2;
    this.address.city = pr.attributes.city;
    this.address.province = pr.attributes.province;
    this.address.postalCode = pr.attributes.postalCode;
    this.address.country = pr.attributes.country;
    this.$emit('input', this.address);
    this.showManualAddress = true;
  }

  updateValue(key: string, value: string) {
    this.address[key] = value;
    this.$emit('input', this.address);
  }

  @Watch('search')
  async onSearch(val: string, oldVal: string) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(
      async () =>
        await placeAutocompleteModule.loadMulti({
          filters: {input: val},
        }),
      1000
    );
  }
}
