







import Vue from 'vue';
import Component from 'vue-class-component';
import {surveyFieldModule} from '@/store/modules';
import {NameValue} from '@/interfaces';
import SubmissionDetail from '@/components/SubmissionDetail.vue';
@Component({
  components: {SubmissionDetail},
})
export default class SurveyResultsPreview extends Vue {
  private headers = [
    {text: 'Name', value: 'name'},
    {text: 'Value', value: 'value'},
  ];
  private userAgent: string = navigator.userAgent;

  get results(): NameValue[] {
    return surveyFieldModule.list.map(item => {
      let value;

      if (item.attributes.value === null) {
        value = '';
      } else if (typeof item.attributes.value !== 'object') {
        value = item.attributes.value;
      } else if (item.attributes.value.value !== undefined) {
        value = item.attributes.value.value;
      } else {
        value = JSON.stringify(item.attributes.value);
      }
      return {
        name: item.attributes.name,
        value: value,
      };
    });
  }
}
