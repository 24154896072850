



































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SurveyFieldItemResourcePartial} from '@/interfaces';
import {mdiAsterisk} from '@mdi/js';
import {InputValidationRules} from 'vuetify';
import linkifyStr from 'linkify-string';

@Component
export default class RadioGroup extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: []}) private items!: SurveyFieldItemResourcePartial[];
  @Prop() private label!: string;
  @Prop({default: false}) private readonly!: boolean;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop() private helpText!: string;
  @Prop({default: false}) private required!: boolean;
  @Prop({default: null}) private value!: string | null;
  @Prop({default: false}) private persistentHint!: boolean;
  @Prop({default: []}) private rules!: InputValidationRules;
  private iconRequired = mdiAsterisk;
  private linkifyStr = linkifyStr;
}
