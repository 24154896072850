import {InputValidationRule} from 'vuetify';

export const ruleRequired: InputValidationRule = v =>
  !!v || v === 0 || 'This field is required';

export const ruleEmail: InputValidationRule = v =>
  /.+@.+/.test(v) || 'This field must be valid';

export const ruleAlwaysInvalid: InputValidationRule = () =>
  'This field must be valid';
