






















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import PageTitle from '@/components/ui/PageTitle.vue';

@Component({
  components: {PageTitle},
})
export default class Header extends Vue {
  @Prop({default: null}) private title!: string;
  @Prop({default: null}) private logoSrc!: string | null;
  @Prop({default: null}) private backgroundSrc!: string;
}
