






import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class Spinner extends Vue {
  @Prop({default: 'primary'}) private color!: string;
}
