































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  NameValue,
  SurveyFieldItemResourcePartial,
  SurveyFieldResource,
  SurveyPageResource,
  SurveyResource,
} from '@/interfaces';
import SurveyFields from '@/components/survey/SurveyFields.vue';
import {Prop} from 'vue-property-decorator';
import {
  surveyFieldModule,
  surveyModule,
  surveyPageModule,
} from '@/store/modules';
import {
  submissionApi,
  submissionResponseApi,
  surveyFieldApi,
  surveyPageApi,
} from '@/api';
import {isArray, orderBy} from 'lodash';
import {QuestionType, ResourceType, Route} from '@/enums';
import {sleep} from '@/utility';

interface NameValueField extends NameValue {
  fieldId: string;
}

@Component({
  components: {SurveyFields},
})
export default class SurveyPages extends Vue {
  @Prop() surveyId!: string;
  private activePageId: string | null = null;
  private pageHistory: string[] = [];
  private valid = false;
  private complete = false;
  private error: string | null = null;
  private submitting = false;

  get busy(): boolean {
    return surveyPageModule.busy;
  }

  get surveyFieldBusy(): boolean {
    return surveyFieldModule.busy;
  }

  get survey(): SurveyResource {
    return surveyModule.find('1');
  }

  get deleting(): boolean {
    return surveyPageModule.deleting;
  }

  get deletingIds(): string[] {
    return surveyPageModule.deletingIds;
  }

  get listing(): boolean {
    return surveyPageModule.listing;
  }

  get saving(): boolean {
    return surveyPageModule.saving;
  }

  get savingIds(): string[] {
    return surveyPageModule.savingIds;
  }

  get surveyPages(): SurveyPageResource[] {
    return orderBy(surveyPageModule.list, ['attributes.order']).filter(
      sp => sp.id !== 'new'
    );
  }

  get surveyPageIds(): string[] {
    return this.surveyPages.map(page => page.id);
  }

  // get activePage() {
  //   return find(this.surveyPages, {id: this.activePageId});
  // }
  get activePageIndex() {
    return this.surveyPageIds.indexOf(String(this.activePageId));
  }

  get activePage(): SurveyPageResource {
    return this.surveyPages[this.activePageIndex];
  }

  get surveyFields(): SurveyFieldResource[] {
    return surveyFieldModule.list
      .filter(surveyField => surveyField.id?.substr(0, 3) !== 'new')
      .filter(field => String(field.attributes.pageId) === this.activePageId);
  }

  // get values(): Record<string, string>[] {
  //   return this.surveyFields.map(field => {
  //     return {[field.attributes.name]: field.attributes.value};
  //   });
  // }

  /**
   * If any fields have a value set that is an object and contains a
   * jumpToPageId property
   */
  get jumps(): SurveyFieldResource[] {
    return this.surveyFields.filter(field => {
      // add radios to this filter
      let selectedItems: SurveyFieldItemResourcePartial[] = [];
      if (
        field.attributes.type === QuestionType.RadioGroup ||
        field.attributes.type === QuestionType.RatingRadio
      ) {
        selectedItems = field.attributes.items.filter(
          item => item.value === field.attributes.value
        );
      }

      return (
        (typeof field.attributes.value === 'object' &&
          field.attributes.value !== null &&
          field.attributes.value.jumpToPageId !== null) ||
        (selectedItems.length > 0 && selectedItems[0].jumpToPageId)
      );
    });
    //.map(field => field.attributes.value as SurveyFieldItemAttributes);
    //.map(field => field)
  }

  async created() {
    surveyPageApi.setSurveyHash(this.$route.params.id);
    try {
      await surveyPageModule.loadMulti({
        filters: {surveyId: this.surveyId},
      });
      this.activePageId = this.surveyPageIds[0];
      surveyFieldApi.setSurveyHash(this.$route.params.id);
      surveyFieldApi.setPreviewMode(this.$route.name === Route.SurveyPreview);
      this.surveyPages.map(page => {
        surveyFieldModule.loadMulti({
          append: true,
          filters: {pageId: page.id},
        });
      });
    } catch (err) {
      console.error(err.response.data);
    }
  }

  prevPage(): void {
    this.activePageId = this.pageHistory.pop() as string;
  }

  nextPage(): void {
    this.pageHistory.push(this.activePageId as string);

    // are there any jumps in this field set?
    if (this.jumps.length > 0) {
      let jumps: SurveyFieldItemResourcePartial[] = [];
      // iterate over all field on the page with jumps
      this.jumps.forEach(field => {
        // get all the items in this field that have a jumpToPageId
        const jumpItems = field.attributes.items.filter(
          item => item.jumpToPageId !== null
        );

        if (field.attributes.type === QuestionType.CheckboxGroup) {
          // merge the jump array with jump items that are selected/checked
          jumps = [
            ...jumps,
            ...jumpItems.filter(
              item =>
                field.attributes.value &&
                isArray(field.attributes.value) &&
                field.attributes.value.includes(item.value)
            ),
          ];
        } else if (
          field.attributes.type === QuestionType.RadioGroup ||
          field.attributes.type === QuestionType.RatingRadio
        ) {
          // get an array of the items that have been selected
          jumps = field.attributes.items.filter(
            item => item.value === field.attributes.value
          );
        } else if (field.attributes.type === QuestionType.Dropdown) {
          jumps = [
            ...jumps,
            ...jumpItems.filter(
              item =>
                field.attributes.value &&
                (field.attributes.value as SurveyFieldItemResourcePartial)
                  .value === item.value
            ),
          ];
        }
      });
      if (jumps.length > 0) {
        // grab the first selected/checked jump id from the jump array
        this.activePageId = String(jumps[0].jumpToPageId);
        return;
      }
    }

    // if there is a nextPageId set, use it
    if (this.activePage.attributes.nextPageId) {
      this.activePageId = String(this.activePage.attributes.nextPageId);
    } else {
      // else get next page in order
      this.activePageId = this.surveyPageIds[this.activePageIndex + 1];
    }
  }

  get results(): NameValueField[] {
    return surveyFieldModule.list.map(item => {
      let value;
      if (item.attributes.value === null) {
        value = '';
      } else if (typeof item.attributes.value !== 'object') {
        value = item.attributes.value;
      } else if (item.attributes.value.value !== undefined) {
        value = item.attributes.value.value;
      } else {
        value = JSON.stringify(item.attributes.value);
      }

      // we need this extra parsing in case a repeatable field is set
      const found = item.attributes.name.match(/^(.*\.r[0-9]+)\.f([0-9]+)$/);
      const fieldId = found ? found[2] : item.id;
      const name = found ? found[1] : item.attributes.name;

      return {
        name: name,
        value: value,
        fieldId: fieldId,
      };
    });
  }

  async submit() {
    this.submitting = true;

    if (process.env.NODE_ENV === 'development') {
      await sleep(2000);
    }

    submissionApi.setSurveyHash(this.$route.params.id);

    try {
      const submission = await submissionApi.post({
        type: ResourceType.Submission,
        attributes: {
          requestUrl: location.href,
        },
      });

      submissionResponseApi.setSurveyHash(this.$route.params.id);

      await submissionResponseApi.postMulti(
        this.results.map(item => ({
          type: ResourceType.SubmissionResponse,
          attributes: {
            submissionId: submission.id,
            name: item.name,
            value: item.value,
            fieldId: item.fieldId,
          },
        }))
      );
      this.error = null;
      this.complete = true;
    } catch (err) {
      console.error(err);
      this.error =
        'There was a problem submitting your survey. Please try again or contact support.';
    } finally {
      this.submitting = false;
    }
  }
}
