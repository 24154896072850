import {Module} from 'vuex-class-modules';
import store from '@/store';
import {surveyApi as api} from '@/api';
import {
  SurveyAttributes as Attributes,
  SurveyCreate as Create,
  SurveyResource as Resource,
} from '@/interfaces';
import {ResourceType, SurveyType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {parseISO} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Survey,
    attributes: {
      id: 0,
      expires: undefined,
      name: '',
      headerImageUrl: '',
      periodStart: undefined,
      periodEnd: undefined,
      url: '',
      sentDate: undefined,
      sentFlag: false,
      surveyId: 0,
      message: '',
      notify: '',
      thankYouMessage: '',
      type: SurveyType.IntegratedOpen,

      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SurveyModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  public cast(item: Resource): Resource {
    item.attributes.expires = parseISO(String(item.attributes.expires));
    item.attributes.periodEnd = parseISO(String(item.attributes.periodEnd));
    item.attributes.periodStart = parseISO(String(item.attributes.periodStart));
    item.attributes.sentDate = parseISO(String(item.attributes.sentDate));
    item.attributes.created = parseISO(String(item.attributes.created));
    item.attributes.modified = parseISO(String(item.attributes.modified));
    return item;
  }
}

export const surveyModule = new SurveyModule(
  {
    store,
    name: ResourceType.Survey,
  },
  () => api,
  defaultItem
);
