








































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SubmissionResource} from '@/interfaces';
import {IResult, UAParser} from 'ua-parser-js';

@Component({
  components: {},
})
export default class SubmissionDetail extends Vue {
  @Prop() private submissionId!: string;

  get parsedUserAgent(): IResult {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    return parser.getResult();
  }
}
