










































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {mdiAsterisk} from '@mdi/js';
import {QuestionType} from '@/enums';
import {
  DisplayControlDataPartial,
  FieldValidation,
  SelectItem,
} from '@/interfaces';
import CheckboxGroup from '@/components/survey/CheckboxGroup.vue';
import DatePicker from '@/components/survey/DatePicker.vue';
import DateTimePicker from '@/components/survey/DateTimePicker.vue';
import RadioGroup from '@/components/survey/RadioGroup.vue';
import DropdownChain from '@/components/survey/DropdownChain.vue';
import {ruleEmail, ruleRequired} from '@/utility';
import {InputValidationRules} from 'vuetify';
import DisplayControl from '@/components/survey/DisplayControl.vue';
import {attachmentApi} from '@/api';
import {DisplayControlAttributes} from '@/interfaces/resources/DisplayControlResource';
import Address from '@/components/survey/Address.vue';
import RatingRadio from '@/components/survey/RatingRadio.vue';
import linkifyStr from 'linkify-string';
import {parseISO} from 'date-fns';

@Component({
  components: {
    RatingRadio,
    Address,
    DisplayControl,
    DropdownChain,
    RadioGroup,
    DateTimePicker,
    DatePicker,
    CheckboxGroup,
  },
})
export default class SurveyField extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: null}) private dropdownChainId!: null | string;
  @Prop({default: null}) private displayControlId!: null | string;
  @Prop({default: null})
  private displayControl!: null | DisplayControlAttributes;
  @Prop({default: []}) private displayControlData!: DisplayControlDataPartial;
  @Prop() private helpText!: string;
  @Prop({default: []}) private items!: SelectItem[];
  @Prop() private label!: string;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop() private preQuestionHelpText!: string;
  @Prop() private preQuestionLabel!: string;
  @Prop({default: false}) private readOnly!: boolean;
  @Prop({default: false}) private persistentHint!: boolean;
  @Prop({default: false}) private sampleValue!: boolean;
  @Prop() private type!: string;
  @Prop({default: {}}) private validation!: FieldValidation;
  @Prop({default: null}) private value!: string | null;
  private checked = false;
  private showQuestion = false;
  private iconRequired = mdiAsterisk;
  private required = false;
  private rules: InputValidationRules = [];
  private questionType = QuestionType;
  private linkifyStr = linkifyStr;
  private parseISO = parseISO;

  get accept(): string {
    if (this.validation.allowedFileTypes === undefined) {
      return '';
    }
    return this.validation.allowedFileTypes.map(type => `.${type}`).join(',');
  }

  created() {
    this.checked = Boolean(this.value);
    // common rules
    if (this.validation.required || this.preQuestionLabel) {
      this.required = true;
      this.rules.push(ruleRequired);
    }
    // type specific rules
    if (this.type === QuestionType.Email) {
      this.rules.push(ruleEmail);
    } else if (
      this.type === QuestionType.File &&
      this.validation.allowedFileTypes !== undefined
    ) {
      this.rules.push(
        value =>
          !value || value.size < 20000000 || 'Attachment must be less than 20M'
      );
      this.rules.push(
        value => !value || this.validateFileType(value) || 'Invalid file type'
      );
    } else if (this.type === QuestionType.Number) {
      this.rules.push(
        value => !value || value >= 0 || 'Value must be greater than 0'
      );
    }
  }

  private async uploadFile(file: File) {
    if (!this.validateFileType(file)) {
      this.$emit('input', '');
      console.error('Upload to server aborted: invalid file type');
      return;
    }
    if (file.size > 20000000) {
      this.$emit('input', '');
      console.error('Upload to server aborted: file > 20M');
      return;
    }

    // upload file to server
    const postedFiles = await attachmentApi.postFile(file);

    this.$emit('input', postedFiles[0].id);
  }

  private validateFileType(file: File) {
    if (!this.accept) {
      return true;
    }
    const extParts = file.name.split('.');
    if (extParts.length === 0) {
      return false;
    }
    const ext = extParts[extParts.length - 1];

    return this.accept.includes(`.${ext}`);
  }
  private resetFile() {
    this.value = '';
  }
}
