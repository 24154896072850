import {Module} from 'vuex-class-modules';
import store from '@/store';
import {surveyPageApi as api} from '@/api';
import {
  SurveyPageAttributes as Attributes,
  SurveyPageCreate as Create,
  SurveyPageResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.SurveyPage,
    attributes: {
      id: 0,
      continue: true,
      surveyId: 0,
      order: 0,
      label: '',
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SurveyPageModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {}

export const surveyPageModule = new SurveyPageModule(
  {
    store,
    name: ResourceType.SurveyPage,
  },
  () => api,
  defaultItem
);
